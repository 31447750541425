import {
  ACTIVE_VERSION,
  CACHE_ID,
  CHUNK_COLLAPSED,
  CHUNK_TYPES,
  FILE_MODULE_LIST,
  GLOBAL_VAR_LIST,
  IS_MAIN_VERSION,
  NOTEBOOK_ID,
  NOTEBOOK_LIST,
  PROJECT_CONSTANT_LIST,
  PROJECT_ID,
  PROJECT_LIST,
  SNAPSHOT_ID,
  USER,
  CURRENT_EDITOR,
  ACTIVE_CONNECTION_SETTINGS,
} from '../constants/general.constants';
import { Project } from '../interfaces/project.interface';
import { GeneralHelpers } from './general.helper';

export class LocalstorageHelper {
  // Setter methods

  static setProjectList(projectList: string): void {
    localStorage.setItem(PROJECT_LIST, projectList);
  }

  static setNotebookList(notebookList: any): void {
    if (Array.isArray(notebookList) && notebookList.length > 0) {
      localStorage.setItem(NOTEBOOK_LIST, JSON.stringify(notebookList));
      return;
    }
    localStorage.setItem(NOTEBOOK_LIST, notebookList);
  }

  static setChunkTypes(chunkTypes: string): void {
    localStorage.setItem(CHUNK_TYPES, chunkTypes);
  }

  static setGlobalVarList(globalVarList: string): void {
    localStorage.setItem(GLOBAL_VAR_LIST, globalVarList);
  }

  static setProjectConstantList(projectConstantList: string): void {
    localStorage.setItem(PROJECT_CONSTANT_LIST, projectConstantList);
  }

  static setFileModuleList(fileModuleList: string): void {
    localStorage.setItem(FILE_MODULE_LIST, fileModuleList);
  }

  static setActiveConnectionSettings(connectionSettings: string): void {
    localStorage.setItem(ACTIVE_CONNECTION_SETTINGS, connectionSettings);
  }

  static getActiveConnectionSettings(): string | null {
    return localStorage.getItem(ACTIVE_CONNECTION_SETTINGS);
  }

  static removeActiveConnectionSettings(): void {
    localStorage.removeItem(ACTIVE_CONNECTION_SETTINGS);
  }

  // Getter methods

  static getProjectList(): string | null {
    return localStorage.getItem(PROJECT_LIST);
  }

  static getNotebookList(): string | null {
    return localStorage.getItem(NOTEBOOK_LIST);
  }

  static getChunkTypes(): string | null {
    return localStorage.getItem(CHUNK_TYPES);
  }

  static getGlobalVarList(): string | null {
    return localStorage.getItem(GLOBAL_VAR_LIST);
  }

  static getProjectConstantList(): string | null {
    return localStorage.getItem(PROJECT_CONSTANT_LIST);
  }

  static getFileModuleList(): string | null {
    return localStorage.getItem(FILE_MODULE_LIST);
  }

  static setUser(user: string): void {
    localStorage.setItem(USER, user);
  }

  static getUser(): string | null {
    return localStorage.getItem(USER);
  }

  static setCurrentEditor(currentEditor: string) {
    localStorage.setItem(CURRENT_EDITOR, currentEditor);
  }

  static getCurrentEditor() {
    return localStorage.getItem(CURRENT_EDITOR);
  }

  static clear(): void {
    localStorage.clear();
  }

  static getProjectListObject(): Project[] | any {
    let projectList = LocalstorageHelper.getProjectList();

    if (projectList !== null) {
      projectList = GeneralHelpers.jsonParse(projectList);

      return projectList;
    }

    return [];
  }

  static setTouchedChunkId(chunkId: string) {
    localStorage.setItem('touchedChunkId', chunkId);
  }

  static getTouchedChunkId() {
    return localStorage.getItem('touchedChunkId');
  }

  static removeTouchedChunkId() {
    localStorage.removeItem('touchedChunkId');
  }

  static getNotebookCache() {
    return localStorage.getItem('notebookCache');
  }

  static setNotebookCache(notebookCache: string) {
    localStorage.setItem('notebookCache', notebookCache);
  }

  static removeNotebookCache() {
    localStorage.removeItem('notebookCache');
  }

  static setCahceId(cacheId: number) {
    localStorage.setItem(CACHE_ID, `${cacheId}`);
  }

  static getCacheId() {
    const cacheId = localStorage.getItem(CACHE_ID);
    return cacheId ? parseInt(cacheId, 10) : null;
  }

  static removeCacheId() {
    localStorage.removeItem(CACHE_ID);
  }

  static getSnapshotId() {
    const snapshotId = localStorage.getItem(SNAPSHOT_ID);
    return snapshotId ? parseInt(snapshotId, 10) : null;
  }

  static setSnapshotId(snapshotId: number) {
    localStorage.setItem(SNAPSHOT_ID, `${snapshotId}`);
  }

  static removeSnapshotId() {
    localStorage.removeItem(SNAPSHOT_ID);
  }
}
