import { ArrowAlias } from "./arrow-alias.interface";

export interface Project {
  projectId?: number;
  userId?: number;
  name?: string;
  createdAt?: Date;
  currentVersionId?: number;
  description?: string;
  lastLogin?: string;
  aliases?: ArrowAlias[];
  users?: ProjectUser[];
  versions?: Version[];
  notebooks?: Notebook[];
  files?: File[];
  defaultState?: string;
  selectedVersion?: Version;
  lastModified: string;
}

export interface Notebook {
  notebookId?: number;
  name?: string;
  projectId?: number;
  fsitemId?: number;
  defaultState?: string;
}

export interface NewNotebook {
  notebook: Notebook;
}

export interface NotebookCreate {
  name: string;
  chunkName: string;
  chunkTypeId: number;
}

export interface NotebookUpdate {
  name: string | undefined;
  defaultState: string;
}

export interface ProjectCreate {
  name: string;
  description?: string;
  notebookName?: string;
  chunkName?: string;
  chunkTypeId?: number;
}

export interface ProjectUser {
  projectId: number;
  userId: number;
  roleId: number;
  roleName: string;
  userName: string;
  projectName: string;
  addedAt: string;
}

export interface Version {
  versionId?: number;
  projectId?: number;
  majorVersion?: number;
  minorVersion?: number;
  fsitemId?: number;
  createdAt?: string;

  notebooks?: Notebook[];
  files?: File[];
  defaultState?: string;
}

export interface VersionCreate {
  majorVersion: number;
  minorVersion: number;
}

export interface VersionUpdate {
  name: string;
  description: string;
  defaultState: string;
}
