<div class="manage-team">
  <form
    [formGroup]="usersForm"
    class="mt-3 mb-3"
    fxLayout="row"
    fxLayoutAlign="space-between start"
  >
    <mat-form-field appearance="outline" class="w100 mr-2">
      <mat-label>Select user</mat-label>
      <input
        type="text"
        placeholder="Type user name"
        aria-label="User"
        matInput
        formControlName="user"
        [matAutocomplete]="userAutocomplete"
      />
      <mat-autocomplete
        #userAutocomplete="matAutocomplete"
        [displayWith]="displayUser"
      >
        <mat-option disabled class="loading pt-3 pb-3" *ngIf="userLoading">
        </mat-option>
        <mat-option disabled *ngIf="noUsersFound"> No team found </mat-option>
        <mat-option
          *ngFor="let user of usersAutocompleteData | async"
          [value]="user"
        >
          {{ user.email }} | {{ user.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w100 mr-2">
      <mat-label>Select role</mat-label>
      <mat-select formControlName="role" placeholder="Select Role">
        <mat-option *ngFor="let role of roles" [value]="role">{{
          role.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <button
      mat-raised-button
      color="primary"
      class="mt-1"
      (click)="sendUserInvite()"
      [disabled]="usersForm.invalid"
    >
      Send invite
    </button>
  </form>
  <hr />
  <h4>Invites sent</h4>

  <mat-list class="invites">
    <mat-list-item *ngFor="let invite of projectInvites">
      <p matListItemTitle>{{ invite.inviteeName }}</p>
      <p matListItemLine>
        Invited by: {{ invite.inviterName }}, Invited at:
        {{ invite.invitedAt | date }}, Role: {{ invite.roleName }}
      </p>
      <button
        matTooltip="Remove invitation"
        class="list-button"
        mat-icon-button
        color="primary"
        [disabled]="
          invite.response !== 'Open' && invite.response !== 'Accepted'
        "
        (click)="manageUserInvitation(invite)"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
</div>
