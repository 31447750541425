import { inject, Injectable } from '@angular/core';
import {
  WSS_API_BASE,
  ARROW,
  WebSocketMessages,
} from '../../constants/general.constants';
import { AuthService } from '@auth0/auth0-angular';
import { OAuthService } from 'angular-oauth2-oidc';
import { GeneralHelpers } from '../../helpers/general.helper';
import { EnMessage } from '../../interfaces/websocket.interface';
import { ToastrService } from 'ngx-toastr';
import { ArrowAliasService } from '../arrow-alias/arrow-alias.service';
import {
  ArrowAliasV2,
  ArrowAliasWSMessage,
  DataSet,
  wsMessageTypes,
} from '../../interfaces/arrow-alias.interface';
import { FileService } from '../file/file.service';
import { EventEmitter2 } from 'eventemitter2';
import { firstValueFrom } from 'rxjs';
import { ArrowClient, CreateTableParams } from './arrow-client';
import { ExecutionContext } from '../../interfaces/chunk/chunk-context.interface';
import { ArrowHelper } from '../../helpers/arrow.helper';
import { Table } from 'apache-arrow';
import { AppStateService } from '../app-state/app-state.service';
import { environment } from '../../../../environments/environment';

/**
 * Represents a service for managing Arrow Websocket connections.
 * This service handles connecting to Arrow aliases, sending and receiving messages,
 * and managing the active connections.
 */
@Injectable({
  providedIn: 'root',
})
export class ArrowWebsocketService {
  #appState = inject(AppStateService);
  private socket!: WebSocket;
  private transId = 0;
  private transactions: Map<number, any> = new Map();
  private pendingTransactions: Map<number, any> = new Map();
  private uploads: Map<number, any> = new Map();
  private emitter: EventEmitter2 = new EventEmitter2({
    wildcard: true,
    ignoreErrors: true,
  });

  #clients = new Map<string, ArrowClient>();
  #currentClient: ArrowClient | undefined;

  constructor(
    private auth0Service: AuthService,
    private oauthService: OAuthService,
    private toastr: ToastrService,
    private arrowAliasService: ArrowAliasService,
    private fileService: FileService
  ) {}

  // ─────────────────────────────────────────────────────────────────────
  // ─── Do connection ───────────────────────────────────────────────────
  // ─────────────────────────────────────────────────────────────────────

  public async connectToAliasNamed(
    aliasName: string,
    context?: ExecutionContext,
    standalone = false
  ): Promise<ArrowClient> {
    const projectId = this.#appState.projectId();
    return new Promise((resolve, reject) => {
      const cacheKey = `${aliasName}-${projectId}`;
      if (!standalone) {
        const existing = this.#clients.get(cacheKey);
        if (existing) {
          resolve(existing);
          return;
        }
      }
      // need to connect and return the connection
      this.connect([aliasName], context)
        .then(() => {
          if (this.#currentClient !== undefined) {
            const client = this.#currentClient;
            if (standalone) {
              this.#clients.delete(cacheKey);
              this.#currentClient = undefined;
            }
            resolve(client);
          } else {
            reject(new Error('failed to connect to alias'));
          }
        })
        .catch(() => {
          reject(new Error('failed to connect to alias'));
        });
    });
  }

  public async connect(
    args: any[],
    chunkContext?: ExecutionContext
  ): Promise<void> {
    if (this === undefined) {
      throw new Error('ArrowWebsocketService is undefined');
    }
    if (!args.length || typeof args[0] !== 'string' || !args[0].trim()) {
      if (chunkContext) {
        chunkContext.addMessage('Invalid or missing alias name.', 'danger');
      }
      return;
    }
    const aliasName = args[0];

    if (aliasName === undefined) {
      throw new Error('Alias name must be provided');
    }

    const alias = this.getAliasByName(aliasName);
    if (!alias) {
      this.toastr.error('Alias not found', 'Error');
      throw new Error('Alias not found');
    }
    const cacheKey = `${alias.name}-${alias.projectId}`;

    const existing = this.#clients.get(cacheKey);
    if (existing) {
      if (!existing.connected) {
        this.#clients.delete(cacheKey);
      } else {
        this.#currentClient = existing;
        return;
      }
    }

    // If no existing connection, set up a new one
    const token = await this.getAuthToken();
    if (!token) {
      throw new Error('Authentication token not available');
    }
    const url = `${WSS_API_BASE}${ARROW}/${alias.aliasId}?token=${token}`;

    const client = new ArrowClient(alias, url);
    this.#clients.set(cacheKey, client);
    this.#currentClient = client;
    client.wsClosedCallback = () => {
      this.#clients.delete(cacheKey);
      if (this.#currentClient === client) this.#currentClient = undefined;
    };
    await client.connect();
  }

  private async getAuthToken(): Promise<string | null> {
    if (environment.authProvider === 'auth0') {
      const token = await firstValueFrom(
        this.auth0Service.getAccessTokenSilently()
      );
      return token;
    } else if (environment.authProvider === 'zitadel') {
      return this.oauthService.getAccessToken();
    } else {
      throw new Error('Unknown auth provider');
    }
  }

  // ─────────────────────────────────────────────────────────────────────
  // ─── Do conenction ───────────────────────────────────────────────────
  // ─────────────────────────────────────────────────────────────────────

  public async disconnect(args: any[]): Promise<void> {
    if (!this.#currentClient) {
      throw new Error('No active connection to disconnect');
    }
    await this.#currentClient.disconnect();
    this.#clients.delete(
      `${this.#currentClient.alias.name}-${this.#currentClient.alias.projectId}`
    );
    this.#currentClient = undefined;
  }

  // ─────────────────────────────────────────────────────────────────────
  // ─── Helpers ─────────────────────────────────────────────────────────
  // ─────────────────────────────────────────────────────────────────────
  private getAliasByName(name: string): ArrowAliasV2 | undefined {
    return this.arrowAliasService
      .aliasV2List()
      .find((item: ArrowAliasV2) => item.name === name);
  }

  // ─────────────────────────────────────────────────────────────────────
  // ─── Send/Receive message ────────────────────────────────────────────
  // ─────────────────────────────────────────────────────────────────────

  // ─────────────────────────────────────────────────────────────────────
  // ─── Shared Methods ──────────────────────────────────────────────────
  // ─────────────────────────────────────────────────────────────────────

  public async createDataSet(
    args: any[],
    chunkContext: ExecutionContext
  ): Promise<void> {
    if (this.#currentClient === undefined) {
      chunkContext.addMessage(
        'No active connection to create data set',
        'danger'
      );
      throw new Error('No active connection to create data set');
    }
    return this.#currentClient.createDataSet(
      args[0],
      args[1],
      args[2],
      args[3]
    );
  }

  public async deleteDataSet(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to delete data set');
    }
    return this.#currentClient.deleteDataSet(args[0]);
  }

  public async createView(args: any[]): Promise<string> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to create view');
    }
    return this.#currentClient.createView(
      args[0],
      args[1],
      args[2],
      args[3],
      args[4]
    );
  }

  public async deleteView(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to delete view');
    }
    return this.#currentClient.deleteView(args[0], args[1]);
  }

  public async listDataSets(args: any[]): Promise<Object[]> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to list data sets');
    }
    return this.#currentClient.listDataSets();
  }

  public async refreshDataSetList(): Promise<DataSet[]> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to refresh data set list');
    }
    return this.#currentClient.refreshDataSetList();
  }

  public async listCloud(args: any[]): Promise<string[]> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to list cloud');
    }
    return this.#currentClient.listCloud(args[0] ?? '');
  }

  public async cloudBasePathExists(args: any[]): Promise<boolean> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to check cloud base path');
    }
    return this.#currentClient.cloudBasePathExists(args[0]);
  }

  public async listViews(args: any[]): Promise<Object[]> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to list views');
    }
    return this.#currentClient.listViews(args[0]);
  }

  public async getSchema(args: any[]): Promise<Object> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to get schema');
    }
    return this.#currentClient.getSchema(args[0]);
  }

  public async initCache(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to init cache');
    }
    return this.#currentClient.initCache(args[0]);
  }

  public async performQuery(args: any[]): Promise<Uint8Array> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to perform query');
    }
    if (args.length == 1) {
      return this.#currentClient.performQuery(undefined, args[0]);
    }
    return this.#currentClient.performQuery(args[0], args[1]);
  }

  public async performQueryAsJson(args: any[]): Promise<string> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to query data as json');
    }
    let dsname = args[0];
    let query = args[1];
    if (args.length == 1) {
      query = args[0];
      dsname = undefined;
    }
    // last param gurantees that the data is returned as arrow table
    const table = (await this.#currentClient.performQuery(
      dsname,
      query,
      true
    )) as any as Table;
    const results = ArrowHelper.tableToJson(table);
    return JSON.stringify(results);
  }

  public async performSqlQuery(args: any[]): Promise<Uint8Array> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to perform query');
    }
    return this.#currentClient.performSqlQuery(args[0], args[1], false);
  }

  public async saveSqlQueryAsTable(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to save query as table');
    }
    return this.#currentClient.saveSqlQueryAsTable(args[0], args[1], args[2]);
  }

  public async doAction(args: any[]): Promise<string> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to do action');
    }
    return this.#currentClient.doAction(args[0], args[1]);
  }

  public async createPut(args: any[]): Promise<number> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to create put');
    }
    return this.#currentClient.createPut(
      args[0],
      args[1],
      args[2],
      args[3],
      args[4]
    );
  }

  public async initPut(args: any[]): Promise<number> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to init put');
    }
    return this.#currentClient.initPut(args[0]);
  }

  public async continuePut(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to continue put');
    }
    return this.#currentClient.continuePut(args[0], args[1]);
  }

  public async endPut(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to end put');
    }
    return this.#currentClient.endPut(args[0]);
  }

  public async listTables(args: any[]): Promise<Object[]> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to list tables');
    }
    return this.#currentClient.listTables();
  }

  public async createTable(args: any[]): Promise<Object> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to create table');
    }
    return this.#currentClient.createTable(
      args[0] as unknown as CreateTableParams
    );
  }

  public async uploadTable(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to upload table');
    }
    return this.#currentClient.uploadTable(args[0], args[1]);
  }

  public async updateTables(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to update tables');
    }
    return this.#currentClient.updateTables();
  }

  public async deleteTable(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to delete table');
    }
    return this.#currentClient.deleteTable(args[0]);
  }

  public async presignUrl(args: any[]): Promise<string> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to get presigned url');
    }
    return this.#currentClient.presignUrl(args[0], args[1], args[2]);
  }

  // ─────────────────────────────────────────────────────────────────────
  // ─── Shared Methods ──────────────────────────────────────────────────
  // ─────────────────────────────────────────────────────────────────────

  // ─────────────────────────────────────────────────────────────────────
  // ─── Private Methods ─────────────────────────────────────────────────
  // ─────────────────────────────────────────────────────────────────────

  private processMessage(message: MessageEvent<any> | undefined) {
    if (!message) {
      return;
    }
    const messageType = GeneralHelpers.trueTypeOf(message?.data);
    const cantRender = 'Could not render';
    let data: any = null;
    switch (messageType) {
      case 'string':
        data = GeneralHelpers.jsonParse(message?.data);
        break;
      case 'array':
        data = message?.data;
        break;
      case 'blob':
        data = `${cantRender}, message type is ${messageType}`;
    }
    return data;
  }

  private buildMessage(type: string, data: any): any {
    let result: any = {};
    let fileFromList = {
      name: '',
    };
    if (data) {
      let { fileName } = data;
      if (fileName) {
        fileFromList = GeneralHelpers.findElementInArrayByProp(
          this.fileService.fileList,
          'name',
          fileName
        );
      }
    }
    switch (type) {
      case WebSocketMessages.CONNECT:
        const { url, token } = data;
        result = {
          message: WebSocketMessages.CONNECT,
          url,
          token: `${token}`,
          trans_id: this.getTransId(),
        };
        break;

      case WebSocketMessages.DISCONNECT:
        result = {
          message: WebSocketMessages.DISCONNECT,
          trans_id: this.getTransId(),
        };
        break;

      case WebSocketMessages.PUT_STREAM:
        let { app_metadata } = data;
        result = {
          message: WebSocketMessages.PUT_STREAM,
          path: fileFromList.name,
          app_metadata: app_metadata,
          trans_id: this.getTransId(),
        };
        break;

      case WebSocketMessages.LIST_FLIGHTS:
        const { criteria } = data;
        result = {
          message: WebSocketMessages.LIST_FLIGHTS,
          trans_id: this.getTransId(),
          criteria,
        };
        break;

      case WebSocketMessages.READ:
        let { ticket } = data;
        result = {
          message: WebSocketMessages.READ,
          ticket: ticket,
          trans_id: this.getTransId(),
        };
        break;

      case WebSocketMessages.GET_INFO:
        result = {
          message: WebSocketMessages.GET_INFO,
          flight: data.flight,
          trans_id: data.trans_id,
        };
        break;
      case WebSocketMessages.LIST_ACTIONS:
        result = {
          message: WebSocketMessages.LIST_ACTIONS,
          trans_id: data.trans_id,
        };
        break;
      case WebSocketMessages.DO_ACTION:
        result = {
          message: WebSocketMessages.DO_ACTION,
          action: data.action,
          argument: data.argument,
          trans_id: data.trans_id,
        };
        break;

      default:
        break;
    }

    return result;
  }

  private getTransId(): number {
    return this.transId++;
  }

  private handleMessage(msg: any): void {
    if (!msg.message) {
      this.emitter.emit(EnMessage.Error, {
        message: 'unknown data received from ws',
      });
      return;
    }
    switch (msg.message) {
      case 'Error':
        this.handleError(msg);
        break;
      case 'Connected':
        this.handleConnected(msg);
        break;
      case 'Disconnected':
        this.handleDisconnected(msg);
        break;
      case 'FlightList':
        this.handleFlightList(msg);
        break;
      case 'StartBinary':
        this.handleStartBinary(msg);
        break;
      case 'UploadComplete':
        this.handlePutComplete(msg);
        break;
      case 'Continue':
        this.handleContinue(msg);
        break;
      case 'Success':
        this.handleSuccess(msg);
        break;
      case 'ActionComplete':
        this.handleActionComplete(msg);
        break;
      case 'ActionList':
        this.handleActionList(msg);
        break;
      case 'BinaryData':
        // this.handleBinaryData(msg);
        break;
      case 'GetInfo':
        this.handleGetInfo(msg);
        break;
      // Add additional case handlers as necessary
      default:
        console.warn(`Unhandled message type: ${msg.message}`);
        break;
    }
    for (let [key, fn] of this.pendingTransactions) {
      if (key === msg.trans_id) {
        fn();
        this.pendingTransactions.delete(key);
        break;
      }
    }
  }

  private handleContinue(msg: any): void {
    const { trans_id } = msg;
    const machine: any = this.uploads.get(trans_id);
    machine.continue();
  }

  private processError(msg: ArrowAliasWSMessage): void {
    if (msg.error === wsMessageTypes.AlreadyConnected) {
      this.toastr.error('Already connected', 'Error');
    }
  }

  private processArrowBuffer(bytesString: string): any {
    const numberArray = bytesString.split(',').map(Number);
    let arrowBuffer = new Uint8Array(numberArray);
    return arrowBuffer;
  }

  private handleActionComplete(message: any): void {
    this.emitter.emit(EnMessage.ActionComplete, message);
  }

  private handleActionList(message: any): void {
    // Implement based on application needs
  }

  private handleConnected(message: any): void {
    this.emitter.emit(EnMessage.ConnectedToFlightServer, {
      trans_id: message.trans_id,
    });
  }

  private handleDisconnected(message: any): void {
    this.emitter.emit(EnMessage.DisconnectedFromFlightServer, {
      trans_id: message.trans_id,
    });
  }

  private handleError(message: any): void {
    const { trans_id, description, error } = message.error;
    this.processError(message);
    this.emitter.emit(EnMessage.FlightError, {
      trans_id,
      description,
      error,
    });
  }

  private handleFlightList(message: any): void {
    if (this.transactions.has(message.trans_id)) {
      message = { verbose: true, ...message };
    }
    this.emitter.emit(EnMessage.FlightList, message);
  }

  private handleGetInfo(message: any): void {
    if (this.transactions.has(message.trans_id)) {
      message = { verbose: true, ...message };
    }
    this.emitter.emit(EnMessage.GetInfo, message);
  }

  private handlePutComplete(message: any): void {
    const { trans_id, file } = message;
    const uploadData: any = this.uploads.get(trans_id);
    if (!uploadData) {
      this.emitter.emit(EnMessage.Error, {
        message: 'upload data not found, aborting upload',
        trans_id,
      });
      return;
    }
    this.uploads.delete(trans_id);
    this.emitter.emit(EnMessage.DisplayOutput, {
      title: 'File Uploaded',
      trans_id,
      message: `${file.name} (${file.fsitemId})`,
    });
  }

  private handleStartBinary(message: any): void {
    const { trans_id } = message;
    const machine: any = this.uploads.get(trans_id);
    machine.startBinary();
  }

  private handleSuccess(message: any): void {
    // Implement based on application needs
  }

  // ─────────────────────────────────────────────────────────────────────
  // ─── Private Methods ─────────────────────────────────────────────────
  // ─────────────────────────────────────────────────────────────────────
}
