import { Injectable } from '@angular/core';
import { Subscription, firstValueFrom, map } from 'rxjs';
import * as python from 'pyodide';
import {
  FILE_EXTENSIONS,
  LOAD_SNAPSHOT,
  RELOAD_NOTEBOOK_FILES,
} from '../../constants/general.constants';
import {
  EHQ,
  CUSTOM_METHODS_EXPORT,
  CUSTOM_METHODS_IMPORT,
  ARROW_FLIGHT,
  STORE,
  CONVERT,
} from '../../constants/additional-methods.constants';
import { GeneralHelpers } from '../../helpers/general.helper';
import { Chunk } from '../../interfaces/chunk/chunk.interface';
import {
  ChunkContext,
  ExecutionContext,
} from '../../interfaces/chunk/chunk-context.interface';
import { FileModule, NotebookFile } from '../../interfaces/file.interface';
import { FileService } from '../file/file.service';
import { NotebookVariablesService } from '../notebook-variables/notebook-variables.service';
import { MessageService } from '../message/message.service';
import { RenderService } from '../render/render.service';
import { CanvasService } from '../canvas/canvas.service';
import { ArrowWebsocketService } from '../arrow-websocket/arrow-websocket.service';
import { NotebookFilesService } from '../notebook-files/notebook-files.service';
import { CacheService } from '../cache/cache.service';
import { FileUnifiedService } from '../file-unified/file-unified.service';
import { ConvertService } from '../convert/convert.service';
// Env
import { environment } from '../../../../environments/environment';
import { DuckDbService } from '../duck-db/duck-db.service';
import { WasmStoreService } from '../wasm/store/wasm-store.service';
import { WasmSearchService } from '../wasm/search/wasm-search.service';
import { WasmViewService } from '../wasm/viewer/wasm-viewer.service';
import { SimpleUIService } from '../simple-ui/simple-ui.service';
import { ProjectVariablesService } from '../project-variables/project-variables.service';

@Injectable({
  providedIn: 'root',
})
export class PythonService {
  public python: any;
  private chunkData: any;
  private chunkContext!: ExecutionContext | ChunkContext;
  private runtime: any;
  // private result = '';
  private isLoaded: boolean = false;
  private messageShown: boolean = false;
  private mountDir = '/wheels';
  private micropip: any;
  // ─────────────────────────────────────────────────────────────────────

  // Message subscription
  private messageSubscription!: Subscription;
  private filesShouldBeReloaded: boolean = false;

  constructor(
    private notebookVariablesService: NotebookVariablesService,
    private messageService: MessageService,
    private notebookFilesService: NotebookFilesService,
    // should be presented in constructor for making it available in the service scope
    // for calling related functionality from pyodide scope with properly services context
    private fileService: FileService,
    private cacheService: CacheService,
    private fileUnifiedService: FileUnifiedService,
    private projectVariablesService: ProjectVariablesService,
    // ─────────────────────────────────────────────────────────────────────
    // Services for custom methods
    private wasmStoreService: WasmStoreService,
    private wasmSearchService: WasmSearchService,
    private wasmViewService: WasmViewService,
    private renderService: RenderService,
    private canvasService: CanvasService,
    private arrowWebsocketService: ArrowWebsocketService,
    private duckDbService: DuckDbService,
    private simpleUIService: SimpleUIService,
    private convertService: ConvertService,
    // ─────────────────────────────────────────────────────────────────────
  ) {
    this.messageSubscription = this.messageService
      .getMessage()
      .subscribe(async (message: any) => {
        if (message?.text === LOAD_SNAPSHOT || message?.text === RELOAD_NOTEBOOK_FILES) {
          this.filesShouldBeReloaded = true;
        }
      });
  }

  async init() {
    this.python =
      this.python ??
      (await python.loadPyodide(
        // Packages latest list https://pyodide.org/en/latest/usage/packages-in-pyodide.html
        {
          indexURL: `https://cdn.jsdelivr.net/pyodide/${environment.pyodideVersion}/full/`,
          fullStdLib: true,
        }
      ));

    // Loaded from CDN packages folder mount
    this.python.FS.mkdir(this.mountDir);
    this.python.FS.mount(
      this.python.FS.filesystems.MEMFS,
      { root: '.' },
      this.mountDir
    );

    await this.python.loadPackage('micropip');
    this.micropip = this.python.pyimport('micropip');
    // ─────────────────────────────────────────────────────────────────────

    this.runtime = this.python.runPythonAsync;

    this.isLoaded = true;
  }

  async run(context: ChunkContext): Promise<Object | undefined> {
    console.log('~~~~~~ chunk run context: ', context);
    this.chunkContext = context;
    this.chunkData = context.getChunk();

    if (this.filesShouldBeReloaded) {
      const notebookFiles = await this.notebookFilesService.getNotebookFiles();

      if (notebookFiles.length > 0) {
        await this.addFilesToPyodide(notebookFiles);
      }

      this.filesShouldBeReloaded = false;
    }

    let { content } = this.chunkData;

    content = this.getContentWithVariablesReplaced(context.getChunkContent());

    content = content.trim();

    if (typeof content !== 'string' || !!content === false) {
      this.chunkData = null;
      return;
    }

    if (this.isLoaded === false) {
      if (!this.messageShown) {
        context.addMessage('Python runtime still loading', 'info');
        this.messageShown = true;
      }

      this.chunkData = null;

      return;
    }

    try {
      await this.processGlobalModules();
      await this.processLoadingPackagesFromImports(content);

      console.time('registerExternalJSModules');
      content = this.registerExternalJSModules(content);
      console.timeEnd('registerExternalJSModules');

      const globalDataVariablesContext = this.addGlobalData(this.chunkData);
      const result = await this.startRuntime(
        content,
        globalDataVariablesContext
      );

      let globalVariables = globalDataVariablesContext
        .toJs(this.python.globals.get('data'))
        .get('data');
      globalDataVariablesContext.destroy();
      globalVariables = GeneralHelpers.fromMapToObject(globalVariables);
      this.notebookVariablesService.addVarsFromRawToList(
        globalVariables,
        this.chunkData
      );

      this.unregisterExternalJSModules(content);

      // Every time this method is called it tries to send actual file content to the server
      // Which is not good because server not allow duplicated file names
      // And file is already exists on the server
      const innerPyodideFiles = this.getMountedFiles();
      this.addFilesToNotebook(innerPyodideFiles);
      // await this.syncFilesystem(true);

      this.chunkData = null;

      if (result.includes('Error')) {
        throw new Error(result);
      }

      context.addOutput(result, 'success', 'python');
      context.setBusy(false, 'done');
      return context.getChunk();
    } catch (error: any) {
      context.addMessage('Script execution failed: ' + error.message, 'danger');
      this.chunkData = null;
      context.addOutput(error, 'error', 'python');
      context.setBusy(false, 'done');
      return context.getChunk();
    }
  }

  private getContentWithVariablesReplaced(content: string) {
    const chunkContent = GeneralHelpers.replaceGlobals(
      content,
      this.projectVariablesService.variableList
    );
    return chunkContent;
  }

  private async addFilesToPyodide(files: NotebookFile[], context = this.chunkContext) {
    files.forEach(async (file: any) => {
      const pathName = `${file.name}`;

      try {
        return this.python.FS.stat(pathName);
      } catch (error) {
        // console.log('Start sync file into pyodide: ', pathName);
      }

      const notebookFile = await this.fileUnifiedService.getFile([file.name], context);

      try {
        const fileData = await GeneralHelpers.convertToUint8Array(
          notebookFile,
          file.fileType
        );

        if (fileData) {
          this.python.FS.writeFile(pathName, fileData);
        }
      } catch (error) {
        console.log('Error sync files into pyodide: ', pathName, error);
      }
    });
  }

  private async addFilesToNotebook(files: Map<string, string>) {
    const notebookFiles: any[] = await firstValueFrom(
      this.cacheService.getCacheContent()
    );
    let notebookFilesShouldBeReloaded = false;

    for (let [timestamp, filePath] of files) {
      const fileName = GeneralHelpers.getNameWithExtension(filePath);
      const isFileAlreadyExist = GeneralHelpers.findElementInArrayByProp(
        notebookFiles,
        'name',
        fileName
      );

      if (!!isFileAlreadyExist) {
        continue;
      }

      const fileContent = this.python.FS.readFile(filePath, {
        encoding: 'binary',
      });
      const blob = new Blob([fileContent], {
        type: 'application/octet-stream',
      });
      const file = new File([blob], fileName, {
        type: 'application/octet-stream',
        lastModified: Number(timestamp),
      });
      const formData = new FormData();
      formData.append('payload', file);

      try {
        const data = await firstValueFrom(
          this.cacheService.createCacheContent(formData)
        );
        notebookFilesShouldBeReloaded = true;
      } catch (error) {
        console.log('addFilesToNotebook error: ', error);
      }
    }

    if (notebookFilesShouldBeReloaded) {
      this.messageService.sendMessage(RELOAD_NOTEBOOK_FILES);
    }
  }

  private async processLoadingPackagesFromImports(content: string) {
    if (content.includes('import')) {
      // loading modules regarding https://pyodide.org/en/latest/usage/loading-packages.html#loading-packages
      await this.python.loadPackagesFromImports(content, {
        checkIntegrity: true,
        errorCallback: (message: string) => {
          console.log(message);
        },
        messageCallback: (message: string) => {
          console.log(message);
        },
      });
    }
  }

  private async startRuntime(code: string = '', data: any) {
    const tryCatchBlockSpacer = new Array(10).fill(' ').join('');
    const setupCode = `
      import sys, io, traceback

      # class CaptureOutput:
      #  def __init__(self, context):
      #    self.context = context
      #  def write(self, text):
      #    self.context.addLog(text.rstrip())
      #  def flush(self):
      #    pass

      async def run_code(namespace = {}):
        """run specified code and return stdout and stderr"""
        # out = CaptureOutput(context)
        out = io.StringIO()
        oldout = sys.stdout
        olderr = sys.stderr

        sys.stdout = sys.stderr = out

        try:
          data = globals()['data']['data']

          ${code.replaceAll('\n', `\n${tryCatchBlockSpacer}`)}

        except:
          traceback.print_exc()
        finally:
          sys.stdout = oldout
          sys.stderr = olderr

        return out.getvalue() or "Code execution complete"
    `;
    this.python.globals.set('data', data);
    this.python.globals.set('context', this.chunkContext);
    await this.runtime(setupCode);
    return await this.runtime(`run_code()`);
  }

  // Global chunk variables should be added from calling
  private addGlobalData(chunkData: Chunk) {
    const varsChunkAccessible: { [key: string]: string } = {};

    if (
      Array.isArray(this.notebookVariablesService.variableList) &&
      this.notebookVariablesService.variableList.length > 0
    ) {
      for (let i = 0; i < this.notebookVariablesService.variableList.length; i++) {
        const element = this.notebookVariablesService.variableList[i];

        if (chunkData.sortOrder >= element.sortOrder) {
          varsChunkAccessible[element.name] = element.value;
        }
      }
    }

    return this.python.toPy({ data: varsChunkAccessible });
  }

  private clean() {
    // this.runtime?.dispose()
  }

  private getMountedFiles(): Map<string, string> {
    const files = new Map<string, string>();
    const iterateFileSystem = (startPath = '/') => {
      const explore = (path: string) => {
        if (path === this.mountDir) {
          return;
        }

        const contents = this.python.FS.readdir(path);
        contents.forEach((item: any) => {
          if (item === '.' || item === '..') {
            // Skip the current and parent directory entries
            return;
          }
          const fullPath = `${path}/${item}`;
          const stats = this.python.FS.stat(fullPath);

          if (this.python.FS.isDir(stats.mode)) {
            // If it's a directory, recurse into it
            // console.log(`Found dir: ${fullPath}`);
            explore(fullPath);
          } else if (this.python.FS.isFile(stats.mode)) {
            // If it's a file, perform some action
            console.log(`Found Mounted file: ${fullPath}`);
            files.set(stats.mtime.getTime(), fullPath);
          }
        });
      };

      try {
        explore(startPath);
      } catch (error) {
        console.log('Finishing filesystem iteration: ', error);
      }
    };

    iterateFileSystem();

    return files;
  }

  private processCustomMethods() {
    const mainHandler: any = [];

    // Export methods
    // Methods will be called from Python
    CUSTOM_METHODS_EXPORT.forEach((element: any) => {
      if (!mainHandler[element.globalName]) {
        mainHandler[element.globalName] = {};
      }

      mainHandler[element.globalName][element.method] = (...args: any) => {
        args = args.map((arg: any) => this.pythonToJs(arg));

        // TODO: hot to propagate runtime name into calling context
        (this as any)[element.serviceToUse][element.methodToCall].apply(
          (this as any)[element.serviceToUse],
          [args, this.chunkContext, 'python']
        );
      };
    });
    // end

    // Import methods
    // Methods will be called from Python
    CUSTOM_METHODS_IMPORT.forEach((element: any) => {
      if (!mainHandler[element.globalName]) {
        mainHandler[element.globalName] = {};
      }

      mainHandler[element.globalName][element.method] = async (...args: any) => {
        // convert pyodide objects to JS objects
        args = args.map((arg: any) => this.pythonToJs(arg));

        const result = await (this as any)[element.serviceToUse][
          element.methodToCall
        ].apply((this as any)[element.serviceToUse], [args, this.chunkContext]);

        return result && this.jsToPython(result);
      };
    });
    // end

    return mainHandler;
  }

  private getPresentedExternalJSModules(content: string) {
    const modules = [EHQ, ARROW_FLIGHT, STORE, CONVERT];
    const presentedExternalJSModules = modules.filter((jsModuleName) =>
      content.includes(`${jsModuleName}.`)
    );

    return presentedExternalJSModules;
  }

  private registerExternalJSModules(content: string) {
    const presentedExternalJSModules = this.getPresentedExternalJSModules(content);
    let importsExternalJSModules = '';

    if (presentedExternalJSModules.length > 0) {
      const mainHandler = this.processCustomMethods();

      presentedExternalJSModules.forEach((jsModuleName) => {
        this.python.registerJsModule(jsModuleName, mainHandler[jsModuleName]);

        importsExternalJSModules += `import ${jsModuleName}\n`;
      });
    }

    return `${importsExternalJSModules}${content}`;
  }

  private unregisterExternalJSModules(content: string) {
    const presentedExternalJSModules =
      this.getPresentedExternalJSModules(content);

    if (presentedExternalJSModules.length > 0) {
      presentedExternalJSModules.forEach((jsModuleName) =>
        this.python.unregisterJsModule(jsModuleName)
      );
    }
  }

  private async processGlobalModules(): Promise<any> {
    const list: FileModule[] = await this.fileService.getFileModuleList([
      FILE_EXTENSIONS.whl,
    ]);
    const hasModules = Array.isArray(list) && list.length > 0;
    const hasGlobal =
      hasModules && list.some((fileModule: FileModule) => fileModule.isGlobal);

    if (hasGlobal) {
      const promises = list.map((item: FileModule) => {
        return this.fileService.getLocalforageItem(item.name);
      });

      return await Promise.all(promises).then(async (results) => {
        for (let index = 0; index < list.length; index++) {
          await this.installPackageWheel({
            ...list[index],
            content: results[index],
          });
        }
      });
    }
  }

  private async installPackageWheel(file: FileModule & { content: any }) {
    // File system should be implemented here and related files could be loaded
    // https://github.com/pyodide/micropip/blob/274d0b3e6186f1b7dff210fff3cc7dc494072d64/micropip/_commands/install.py
    // https://pyodide.org/en/stable/usage/api/js-api.html#pyodide.FS
    // https://emscripten.org/docs/api_reference/Filesystem-API.html
    const filePath = `${this.mountDir}/${file.name}`;
    let fileStats;

    try {
      fileStats = this.python.FS.stat(filePath);
    } catch (error) {
      console.log('Error python.FS.stat: ', error);
    }

    if (!fileStats?.size && file?.content instanceof ArrayBuffer) {
      try {
        const fileData = new Uint8Array(file.content);

        this.python.FS.writeFile(filePath, fileData, { encoding: 'utf8' });

        await this.micropip.install(`emfs:${filePath}`);
      } catch (error) {
        console.log('Error micropip.install: ', error);
      }
    }
  }

  // form pyodide python to JS types convertor
  private pythonToJs(pyData: any): any {
    if (pyData?.type === 'dict') {
      const jsObject: { [key: string]: any } = {};

      for (const key of pyData.keys()) {
        jsObject[key] = this.pythonToJs(pyData.get(key));
      }

      return jsObject;
    } else if (pyData?.type === 'list' || pyData?.type === 'tuple') {
      const jsArray: any[] = [];

      for (const item of pyData) {
        jsArray.push(this.pythonToJs(item));
      }

      return jsArray;
    } else if (pyData?.type === 'set') {
      const jsSet: Set<any> = new Set();

      for (const item of pyData) {
        jsSet.add(this.pythonToJs(item));
      }

      return jsSet;
    } else if (pyData?.type === 'memoryview') {
      let data = pyData.toJs();

      if (data instanceof Uint8Array) {
        data = Array.from(data).join(',');
      }

      return data;
    } else if (
      pyData?.type?.includes('ImageFile')
      || pyData?.type === 'DataFrame'
    ) {
      // TODO: found related data and fix issues with it
      // ask user to use uint8array instead of direct ImageFile
      return pyData.toJs();
    } else if (pyData instanceof this.python.ffi.PyProxy) {
      return pyData.toJs();
    } else {
      return pyData;
    }
  }

  private jsToPython(jsObject: any): any {
    const jsToPython = `
      from pyodide.ffi import JsProxy

      def process_data(data):
          # Check if it's a JsProxy object
          if isinstance(data, JsProxy):
              # Convert other JsProxy objects to Python objects
              data = data.to_py()

              # Check for Uint8Array specifically
              if hasattr(data, 'BYTES_PER_ELEMENT') and hasattr(data, 'buffer'):
                  # Convert Uint8Array to Python bytes
                  return bytes(data)

          if isinstance(data, dict):
              for key, value in data.items():
                  data[key] = process_data(value) # Recursive call

          elif isinstance(data, list):
              for i, value in enumerate(data):
                  data[i] = process_data(value) # Recursive call

          elif isinstance(data, set):
              data = {process_data(value) for value in data} # Recursive call

          return data
    `;
    const jsObjectName = `jsObjectToPython_${GeneralHelpers.randomNumber()}`;
    (window as any)[jsObjectName] = jsObject;
    const result = this.python.runPython(`
      from js import ${jsObjectName}
      ${jsToPython}
      process_data(${jsObjectName})
    `);

    // TODO: think about how to remove this from window scope and move to python registry js data
    delete (window as any)[jsObjectName];

    return result;
  }
}
